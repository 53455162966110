<template>
  <div class="fonter-com">
    <div class="fonter-content">
      <div class="left">
        <div class="left-title">
          <div class="logo">
            <img
              src="../assets/img/logo.png"
              @click="
                $router.push({ name: 'Index' });
                setNavIndex('a');
              "
              alt=""
            />
            <!-- <p>第三方采购交易平台</p> -->
          </div>
          <div class="left-nav">
            <span
              @click="
                $router.push({ name: 'Index' });
                setNavIndex('a');
              "
              >首页</span
            >
            <!-- <el-divider direction="vertical"></el-divider> -->
            <!-- <span @click="$router.push({ name: 'AboutUs' })">关于我们</span> -->
            <!-- <el-divider direction="vertical"></el-divider>
            <span @click="$router.push({ name: 'ProServer' })">产品与服务</span> -->
            <el-divider direction="vertical"></el-divider>
            <span @click="openEnd">后台入口</span>
          </div>
        </div>
        <div class="info-box">
          <span>深圳市百川智慧商展云科技有限公司</span>
          <span
            ><i class="iconfont icon-weizhidizhi"></i
            >广东省深圳市龙华区大浪街道新石社区浪静路 3 号 2 栋 2 层</span
          >
          <span><i class="iconfont icon-shouji-copy"></i>0755-23736340</span>
          <span
            ><i class="iconfont icon-youxiangrenzheng"></i
            >2169047119@qq.com</span
          >
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">Copyright © 2022 粤ICP备2023006320号</a>
          <!-- <span>Copyright 2022 百川 版权所有</span> -->
          <!-- <span>深圳市大浪时尚小镇卡尔丹顿时尚创意产业园 A 栋二楼</span> -->
          <span class="gongan"><img src="../assets/img/gongan.png"/><span>粤公网安备 44030902003769号</span></span>
          <a href="https://bcceglobal.com/img/user.pdf" target="_blank">用户服务协议</a>
        </div>
      </div>
      <div class="right">
        <div class="code-img">
          <img src="../assets/img/info_code.jpg" alt="" />
          <p>百川平台联系方式</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["setNavIndex"]),
    openEnd(){
      window.open('https://server.bcceglobal.com')
    }
  },
};
</script>
<style lang="scss" scoped>
.fonter-com {
  background-color: #42464b;
  padding: 20px 0;

  .fonter-content {
    @include flexbox(row, space-between, center);
    width: 80%;
    margin: 0 auto;
    font-size: 14px;

    @media screen and (max-width: 1200px) {
      @include flexbox(column, flex-start);
      font-size: 0.12rem;
      width: 100%;
      padding: 0 0.2rem;
      box-sizing: border-box;
    }

    .left {
      width: 70%;

      @media screen and (max-width: 1200px) {
        // @include flexbox(column, flex-start, center);
        width: 100%;
      }

      .left-title {
        @include flexbox(row, flex-start, center);

        .logo {
          color: #fff;
          @include flexbox(column, flex-start, center);
          letter-spacing: 14px;

          @media screen and (max-width: 1200px) {
            letter-spacing: 0.04rem;
          }

          > img {
            height: 60px;
            margin-bottom: 10px;
            cursor: pointer;

            @media screen and (max-width: 1200px) {
              // @include flexbox(column, flex-start, center);
              height: 0.6rem;
            }
          }
        }

        .left-nav {
          color: #fff;
          margin-left: 30px;

          @media screen and (max-width: 1200px) {
            margin-left: 0.2rem;
            font-size: 14px;
          }

          > span {
            cursor: pointer;

            &:hover {
              color: $primaryColor;
            }
          }

          .el-divider {
            margin: 0 20px;

            @media screen and (max-width: 1200px) {
              margin: 0 0.2rem;
            }
          }
        }
      }

      .info-box {
        color: #b6b6b6;
        .gongan{
          white-space: nowrap;
          >span{
            padding-left: 3%;
            box-sizing: border-box;
            vertical-align: middle;
            white-space: nowrap;
          }
          &:hover{
            color: rgb(65, 161, 184);
          }
        }
        i {
          margin-right: 2px;
          font-size: 14px;
        }

        @include flexbox(row) {
          flex-wrap: wrap;
        }

        > span,a {
          margin-right: 40px;
          margin-top: 10px;
        }
        >a{
          color: #b6b6b6;
          text-decoration: none;
          &:hover{
            text-decoration: revert;
          }
        }
      }
    }

    .right {
      flex: 1;
      overflow: auto;
      @include flexbox(row, center, center);

      @media screen and (max-width: 1200px) {
        margin-top: 0.4rem;
      }

      .code-img {
        @include flexbox(column, center, center);

        > img {
          width: 140px;
        }

        > p {
          color: #a3a3a3;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>