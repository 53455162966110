<template>
  <div class="page-view" ref="appref" @scroll="appScroll($event)">
    <slot></slot>
    <div class="customer" :class="{ 'cus-up': cusUp }" @click.stop="toCustomer">
      <div class="impage" @click.stop="$router.push({ name: 'AboutUs' })">
        <img src="../assets/img/BCCE.png" />
        <div>关于我们</div>
      </div>
      <div class="customer-img">
        <el-popover placement="left" width="150" v-model="visible">
          <img v-lazy="require('@/assets/img/customer_code.png')" width="150" alt="" />
          <center>打开手机扫一扫</center>
          <img slot="reference" src="../assets/img/customer.png" alt="" />
        </el-popover>
      </div>
      <span class="customer-txt">联系客服</span>
      <el-backtop :target="'.' + upClass"></el-backtop>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "pageView",
  props: {
    upClass: {
      type: String,
      default: "page-view",
    },
  },
  data() {
    return {
      cusUp: false,
      visible: false,
    };
  },
  methods: {
    appScroll(e) {
      this.cusUp = e.target.scrollTop > 200 ? true : false;
    },
    toCustomer() {
      axios
        .get(
          "https://zzx.zhenonline.com/mobile/user/getShop?companyid=2&shopType=2" //百川的
          // "https://zzx.zhenonline.com/mobile/user/getShop?companyid=58&shopType=2"//我们的
        )
        .then((res) => {
          if (res.data.code === 200) {
            let url = res.data.data.customerServiceUrl;
          }
        });
      if (this.isPhone()) {
        this.visible = false;
        axios
          .get(
            "https://zzx.zhenonline.com/mobile/user/getShop?companyid=2&shopType=2"
            // "https://zzx.zhenonline.com/mobile/user/getShop?companyid=58&shopType=2"
          )
          .then((res) => {
            if (res.data.code === 200) {
              let url = res.data.data.customerServiceUrl;
              window.open(url);
            }
          });
      } else {
        this.visible = true;
      }
    },
    isPhone() {
      var info = navigator.userAgent;
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone = /mobile/i.test(info);
      //如果包含“Mobile”（是手机设备）则返回true
      return isPhone;
    },
  },
};
</script>
<style>
.page-view {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
<style lang="scss" scoped>
.el-backtop {
  margin-top: 23%;
  position: static;
  color: $primaryColor;
  width: max(30px,3vw);
  height: max(30px,3vw);
  z-index: 999;
  right: 0px !important;
  bottom: 0px !important;
}

.customer {
  position: fixed;
  z-index: 999;
  right: 40px;
  bottom: 30px;
  @include flexbox(column, flex-start, center);
  cursor: pointer;
  transition: all 0.3s linear;

  .customer-img {
    width: max(30px,3vw);
    height: max(30px,3vw);
    border-radius: 50%;
    box-shadow: 0 0 6px rgb(0 0 0 / 12%);
    background-color: #fff;
    overflow: hidden;
    margin-top: max(10px,1.3vw);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .customer-txt {
    color: $primaryColor;
    font-size: 14px;
  }

  .impage {
    color: $primaryColor;
    font-size: 14px;
    text-align: center;

    >img {
      width: max(30px,3vw);
      height: max(30px,3vw);
      border-radius: 50%;
      box-shadow: 0 0 6px rgb(0 0 0 / 12%);
      background-color: #439C4C;
      overflow: hidden;
    }
  }
}
</style>