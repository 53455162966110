import axios from "axios";
import axiosInstance from "./axiosInstance";
// qs.parse()是将URL解析成对象的形式
// qs.stringify()是将对象 序列化成URL的形式，以&进行拼接
import qs from "qs";
const API = {

  // 轮播图
  getBanner(obj) {
    return axiosInstance.post(`/advertSeat/selectSn/${obj}`);
  },

  //获取供应商
  selectSn(obj) {
    return axiosInstance.post('/arpictset/list', qs.stringify(obj));
  },
  //获取供应商公司主页
  getComInfo(obj) {
    return axiosInstance.post('/arpictset/supplier_one', qs.stringify(obj));
  },
  // 获取文章列表
  getListChoose(obj) {
    return axiosInstance.post(`/arpictset/listChoose/${obj.bcCompanyType}/${obj.bcce}/${obj.type}`, qs.stringify(obj));
  },
  // 获取文章详情 
  getChooseDetail(id, type = 16) {
    return axiosInstance.post('/mobile/article/detail', qs.stringify({
      id, type
    }));
  },
  // 获取公司文章列表编号
  getComListId(obj) {
    return axiosInstance.post(`/arpictset/16/listtest/${obj.conpanyid}`);
  },
  // 获取公司文章列表
  getComList(obj) {
    return axiosInstance.post(`/mobile/article/list`, qs.stringify(obj));
  },
  //获取材料分类
  getMaterClass(categoryId) {
    return axiosInstance.get(`/wx/category/8/list`, {
      params: {
        categoryId
      }
    });
  },

  //获取材料列表
  getMaterList(obj) {
    return axiosInstance.post(`/wx/goodsMain/goodsLIst`, qs.stringify(obj));
  },

  //获取一元列表
  getMaterList1(obj) {
    return axiosInstance.post(`/wx/goodsMain/goodsLIstPrice`, qs.stringify(obj));
  },

  // 获取商品详情
  getGoodsDetailsApi(id) {
    return axiosInstance.post(`/wx/goodsMain/detail?id=${id}`);
  },
  // 获取商品列表
  getGoodsListApi(data) {
    return axiosInstance.post(`/wx/goodsMain/goodsLIst`, data);
  },
  // 获取商品规格
  getGoodsMarqueeApi(id) {
    return axiosInstance.post(`/wx/goodsMain/goods?goodsId=${id}`);
  },

  // 获取省市区
  getAddress(obj) {
    return axiosInstance.post(`/arpictset/address`, qs.stringify(obj));
  },
  //手机号发起验证码
  sendCode(obj) {
    return axiosInstance.post(`/mobile/sdk/send`, qs.stringify(obj));
  },
  // 验证手机验证码
  verification(obj) {
    return axiosInstance.post(`/mobile/sdk/verification`, qs.stringify(obj));
  },
  //上传营业执照
  uploadLicense(obj) {
    return axiosInstance.post(`/process/attachment/upload`, qs.stringify(obj));
  },
  // 注册
  register(obj) {
    return axiosInstance.post(`/arpictset/add`, qs.stringify(obj));
  },



  //闲物互联-----------------------------------------------------------------
  getMaterdd() {//闲物互联顶部类别接口
    return axiosInstance.get(`/wx/category/17/list`);
  },
  getMaterdds() {//闲物互联前四条类别接口
    return axiosInstance.get(`/wx/category/17/freeZoneList?current=4`);
  },


  getMatergood(obj) {//闲物互联下部列表接口
    return axiosInstance.post(`/wx/goodsMain/goodsLIst`, qs.stringify(obj));
  },

  addShopCar(data) {//加入购物车
    return axiosInstance.post(`/wx/cart/addProduct`, qs.stringify(data))
  },

  makeShopNumber(data) {//更新购物车数量
    return axiosInstance.post(`/wx/cart/updateProduct`, qs.stringify(data))
  },

  getShopIds(data) {//提交购物车数据
    return axiosInstance.post(`/wx/purchaseMobile/settleAccount`, data)
  },

  makeOrder(data) {//生成订单
    // return axiosInstance.post(`/wx/purchaseMobile/save`, qs.stringify(data))
    // return axiosInstance.post(`/wx/purchaseMobile/saveImmediately`, qs.stringify(data))
    return axiosInstance.post(`/wx/purchaseMobile/saveOrder`, data)
  },

  getInfo(data) {//查询订单详情
    return axiosInstance.post(`/wx/purchaseMobile/sale/orderDetail`, data)
  },
  getInfos(data) {//查询全部订单详情
    return axiosInstance.post(`/wx/purchaseMobile/sale/orderDetailSale`, data)
  },

  changeOrderStateApi(data) {// 取消订单/确认收货
    return axiosInstance.post(`/wx/purchaseMobile/sale/changeOrderStateSale`, data);
  },

  giveMoney(data) {//生成微信支付
    return axiosInstance.post(`/mobile/weixinPay`, qs.stringify(data))
  },
  giveMoneyTwo(data) {//生成支付宝支付
    return axiosInstance.post(`/mobile/new/aliPay`, qs.stringify(data))
  },

  askMoney(data) {//查询微信支付结果
    return axiosInstance.post(`/mobile/getWeixinResult`, qs.stringify(data))
  },
  askWhere(data) {//查询物流
    return axiosInstance.post(`/express/query/expressMsg`, data)
  },



  // 个人中心获取订单列表------------------------------------------------------------------------------
  getOrderListApi(data) {
    return axiosInstance.post(`/wx/purchaseMobile/sale/orderListSale`, qs.stringify(data))
  },
  // 三级联动地址
  getSelectAddressApi(data) {
    // return axiosInstance.post(`/arpictset/address`, qs.stringify(data));
    return axiosInstance.post(`/wx/myReceiver/address`, qs.stringify(data));
  },
  // 添加收货地址
  addAddressApi(data) {
    return axiosInstance.post(`/wx/myReceiver/add`, data);
  },
  //获取收货地址
  getDiZhi(data) {
    return axiosInstance.post(`/wx/myReceiver/jsonlist`, data)
  },
  //修改收货地址
  ChangeDiZhi(data) {
    return axiosInstance.post(`/wx/myReceiver/update`, data)
  },
  //删除收货地址
  delDiZhi(data) {
    return axiosInstance.post(`/wx/myReceiver/delete`, data)
  },
  // 添加收藏列表
  addCollect(data) {
    return axiosInstance.post(`/wx/mobile/upvote/add`, data);
  },
  // 删除收藏列表
  delCollect(data) {
    return axiosInstance.post(`/wx/mobile/upvote/cannel`, data);
  },
  // 获取收藏列表
  getCollectListApi(data) {
    return axiosInstance.post(`/wx/mobile/upvote/list`, data);
  },
  //获取购物车列表
  getShops() {
    return axiosInstance.post(`/wx/cart/list`)
  },
  //删除购物车
  delShops(data) {
    return axiosInstance.post(`/wx/cart/deleteCart`, data)
  },
  //个人中心注册
  register1(obj) {
    return axiosInstance.post(`/wx/home/registrationH5`, qs.stringify(obj));
  },
  //登录个人中心
  Login(obj) {
    return axiosInstance.post(`/wx/home/toGrantfontloginH5`, qs.stringify(obj));
  },

  //订单评价
  PingJia(obj) {
    return axiosInstance.post(`/mobile/goodsComment/add`, obj, { headers: { 'Content-Type': 'application/json' } });
  },

  //评价查询
  askPingJia(obj) {
    return axiosInstance.get(`/mobile/goodsMain/goodsCommentList?goodsMainId=${obj}`);
  },
};

export default API;
