<template>
    <div class="title-nav-box">
        <div class="title-nav" v-if="!isMobile">
            <div class="title">
                <div class="logo">
                    <img class="logo-img" src="../assets/img/logo.png" @click="onLogo" alt="" />
                    <el-menu :default-active="navIndex" class="el-menu-demo" mode="horizontal" router
                        @select="handleSelect">
                        <!-- <el-menu-item :route="{ name: 'Index' }" index="a">首页</el-menu-item> -->
                        <el-menu-item :route="{ name: 'BrandSide' }" index="a">建店方案</el-menu-item>
                        <el-menu-item :route="{ name: 'DesignCom' }" index="c">设计优选</el-menu-item>
                        <el-menu-item :route="{ name: 'PropsMake' }" index="d">道具优选</el-menu-item>
                        <el-menu-item :route="{ name: 'ProvideMater' }" index="e">材料优选</el-menu-item>
                        <el-menu-item :route="{ name: 'Decoration' }" index="f">装潢优选</el-menu-item>
                        <el-menu-item :route="{ name: 'Supervisor' }" index="g">监理优选</el-menu-item>
                        <el-menu-item :route="{ name: 'Logistics' }" index="h">物流优选</el-menu-item>
                        <el-menu-item :route="{ name: 'Metaverse' }" index="i">元宇宙</el-menu-item>
                        <el-menu-item :route="{ name: 'ShareGoods' }" index="j">闲物互联</el-menu-item>
                        <el-menu-item :route="{ name: 'AboutUs' }" index="b">关于我们</el-menu-item>
                    </el-menu>
                </div>
                <div class="search">
                    <el-input placeholder="请输入内容" clearable v-model.trim="searchInput"
                        @keypress.enter.native="searchBtn(select)" class="input-with-select">
                        <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 80px;">
                            <el-option label="找公司" value="1"></el-option>
                            <el-option label="找材料" value="3"></el-option>
                        </el-select>
                        <el-button slot="append" @click="searchBtn(select)">搜索</el-button>
                    </el-input>
                    <div class="login" @click="openEnd">后台入口</div>
                </div>
            </div>
        </div>
        <div class="meta-nav" v-else>
            <div class="title-box">
                <div class="left">
                    <img class="logo-img" src="../assets/img/logo.png" @click="onLogo" alt="" />
                </div>
                <div class="right">
                    <i class="iconfont" :class="[showNav ? 'icon-guanbi' : 'icon-gengduo']" @click="showNav = !showNav"></i>
                </div>
            </div>
            <el-collapse-transition>
                <div class="nav-box" v-show="showNav">
                    <el-menu :default-active="navIndex" class="el-menu-vertical-demo" background-color="#171d27"
                        text-color="#fff" active-text-color="#439C4C" router @select="selectRouter">
                        <!-- <el-menu-item style="height: 50px;" :route="{ name: 'Index' }" index="a">首页</el-menu-item> -->
                        <el-menu-item style="height: 50px;" :route="{ name: 'BrandSide' }" index="a">建店方案</el-menu-item>
                        <el-menu-item :route="{ name: 'DesignCom' }" index="c">设计优选</el-menu-item>
                        <el-menu-item style="height: 50px;" :route="{ name: 'PropsMake' }" index="d">道具优选</el-menu-item>
                        <el-menu-item style="height: 50px;" :route="{ name: 'ProvideMater' }" index="e">材料优选</el-menu-item>
                        <el-menu-item style="height: 50px;" :route="{ name: 'Decoration' }" index="f">装潢优选</el-menu-item>
                        <el-menu-item style="height: 50px;" :route="{ name: 'Supervisor' }" index="g">监理优选</el-menu-item>
                        <el-menu-item style="height: 50px;" :route="{ name: 'Logistics' }" index="h">物流优选</el-menu-item>
                        <el-menu-item style="height: 50px;" :route="{ name: 'Metaverse' }" index="i">元宇宙</el-menu-item>
                        <el-menu-item style="height: 50px;" :route="{ name: 'ShareGoods' }" index="j">闲物互联</el-menu-item>
                        <el-menu-item :route="{ name: 'AboutUs' }" index="b">关于我们</el-menu-item>
                        <el-menu-item style="height: 50px;" @click="openEnd">后台入口</el-menu-item>
                    </el-menu>
                </div>
            </el-collapse-transition>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "TitleNav",
    props: ["routeName"],
    data() {
        return {
            searchInput: "",
            select: "1",
            showNav: false,
            defaultActive: "",
        };
    },
    computed: {
        ...mapState(["screenWidth", "navIndex", "infoObj"]),
        isMobile() {
            if (this.screenWidth < 768) {
                return true;
            } else {
                return false;
            }
        },
        activeIndex() {
            let routeActive;
            if (this.routeName.newRoute) {
                routeActive = this.routeName.oldRoute;
            } else {
                routeActive = this.$route.meta.index;
            }
            return routeActive;
        },
        islogin() {//监听登录状态,控制个人中心显示和隐藏
            if (this.infoObj.account && this.infoObj.phone && window.localStorage.getItem('token')) {
                return true
            } else {
                window.localStorage.removeItem('token')
                return false
            }
        }
    },

    methods: {
        ...mapActions(["setSearch", "setNavIndex"]),
        async searchBtn(select) {
            if (select === "1") {
                this.setSearch({
                    select: this.select,
                    value: this.searchInput,
                });
                this.$router.push({
                    name: "DesignComAll",
                    params: {
                        name: this.searchInput,
                    },
                });
            } else if (select === "3") {
                this.setSearch({
                    select: this.select,
                    value: this.searchInput,
                });
                this.$router.push({
                    name: "ProvideMaterAll",
                    params: {
                        name: this.searchInput,
                    },
                });
            }
        },
        //搜索框改变值出发
        changeSearch(value) {
            this.setSearch({
                select: this.select,
                value,
            });
            this.$router.push({
                name: "DesignComAll",
                params: {
                    name: this.searchInput,
                },
            });
        },

        openEnd() {
            window.open('https://server.bcceglobal.com')
        },

        onLogo() {
            this.$router.push({ name: "Index" });
            // this.defaultActive = "a";
            this.setNavIndex("");
        },
        handleSelect(key, keyPath) {
            // this.defaultActive = key;
            this.setNavIndex(key);
        },
        selectRouter(v) {
            this.showNav = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.title-nav-box {
    background-color: #fff;
    box-shadow: 1px 3px 1px 0px rgba(99, 112, 106, 0.09);
    position: sticky;
    top: 0;
    z-index: 99;

    .title-nav {
        padding: 0.8vw 1vw;

        .title {
            @include flexbox(row, space-between, center);

            .logo {
                flex: 1;
                @include flexbox(row, flex-start, center);

                .logo-img {
                    height: 3vw;
                    margin-right: 20px;
                    cursor: pointer;
                }

                :deep(.el-menu) {
                    display: inline-block;
                    border: none;
                    width: 100%;

                    .el-menu-item {
                        line-height: 0px;
                        height: auto;
                        color: $primaryColor;
                        font-size: 0.8vw;
                        padding: 1vw;

                        &.is-active {
                            background-color: $primaryColor;
                            color: #fff;
                            border: none;
                        }

                        &:not(.is-disabled):hover {
                            background-color: $primaryColor;
                            color: #fff;
                        }
                    }

                    .el-submenu__title {
                        line-height: 0px;
                        height: auto;
                        color: $primaryColor;
                        font-size: 0.8vw;
                        padding: 0.7vw;

                        >i {
                            color: $primaryColor;
                            font-size: 0.8vw;
                        }
                    }

                    .el-submenu.is-active .el-submenu__title {
                        border: none;
                        background-color: $primaryColor;
                        color: #fff;

                        >i {
                            color: #fff;
                        }
                    }
                }
            }

            .search {
                @include flexbox(row, space-between, center);

                :deep(.el-input) {
                    .el-input-group__prepend {
                        background-color: transparent;

                        .el-select {
                            .el-input__inner:focus {
                                border-color: transparent;
                            }

                            @at-root {
                                .el-select-dropdown__item.selected {
                                    color: $primaryColor;
                                }
                            }
                        }
                    }

                    .el-input__inner:focus {
                        border-color: $primaryColor;
                    }

                    .el-input__inner {
                        font-size: 12px;
                        padding-right: 10%;
                    }

                    .el-input-group__append {
                        background-color: $primaryColor;
                        border: 1px solid $primaryColor;
                        color: #fff;
                    }
                }

                .login {
                    margin-left: 2%;
                    white-space: nowrap;
                    font-size: 14px;
                    cursor: pointer;
                    color: #333;
                    &:hover {
                        color: $primaryColor;
                    }
                }

                .user {
                    margin-left: 5%;
                    cursor: pointer;
                    font-size: max(20px, 1.6vw);
                    color: #666;
                }
            }
        }

        .nav-box {
            @include flexbox(row, space-between, center);
            margin-top: 18px;

            .nav {
                @include flexbox(row);

                >li {
                    font-weight: 400;
                    color: #000000;
                    font-size: 12px;
                    margin-right: 43px;
                    cursor: pointer;

                    &.active {
                        color: $primaryColor;
                    }
                }
            }

            .nav-role {
                @include flexbox(row);

                >li {
                    margin-right: 18px;
                    font-weight: bold;
                    cursor: pointer;
                    font-size: 12px;

                    i {
                        font-weight: normal;
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .meta-nav {
        background-color: #171d27;

        .title-box {
            @include flexbox(row, space-between, center);
            color: #ffffff;
            padding: 10px;
            box-sizing: border-box;

            .left {
                .logo-img {
                    height: 40px;
                }
            }

            .right {
                i {
                    font-size: 24px;
                    cursor: pointer;
                }
            }
        }

        .nav-box {
            // position: absolute;
        }
    }
}

.goods-menu {
    // :deep(.el-menu-item.is-disabled) {
    color: red !important;

    &.el-menu-item.is-disabled {
        // opacity: 1;
    }

    // }
}
</style>