<template>
  <div id="app">
    <router-view></router-view>
    <!-- <div class="home" ref="homeRef">
      <title-nav ref="titleNavRef" :route-name="routeName" v-if="$route.name!='Register'"></title-nav>
      <div class="home-content">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </div>
    </div> -->
  </div>
</template>
<script>
import TitleNav from "@/components/TitleNav.vue";
import { mapActions } from "vuex";
export default {
  components: { TitleNav },
  data() {
    return {
      routeName: { newRoute: "", oldRoute: "" },
    };
  },
  created() {
    this.setScreenWidth(document.body.clientWidth);
    window.onresize = () => {
      return (() => {
        this.setScreenWidth(document.body.clientWidth);
      })();
    };
  },
  methods: {
    ...mapActions(["setScreenWidth"]),
  },
};
</script>

<style lang="scss">
@import url("https://at.alicdn.com/t/c/font_3736656_ckjs9sp78ed.css");

* {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

html {
  @media screen and (max-width: 1200px) {
    font-size: calc(100vw / 750 * 100);
  }

  font-family: "Microsoft YaHei";
  color: #333;
}

body {
  @media screen and (max-width: 1200px) {
    font-size: 0.16rem;
  }
}

#app {
  height: 100vh;
  overflow: auto;

  img {
    vertical-align: middle;
  }
  .container-tltle {
    text-align: center;
    font-size: 30px;
    margin-bottom: 30px;
    position: relative;

    &::after {
      content: "";
      display: inline-block;
      border-radius: 50%;
      position: absolute;
      transform: translate(-55%, -10%);
      width: 22px;
      height: 22px;
      background: linear-gradient(233deg,
          #469c4c 0%,
          rgba(217, 217, 217, 0) 64%);
    }

    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  .el-backtop {
    color: $primaryColor;
    // width: 50px;
    // height: 50px;
    font-size: 32px;
  }

  .customer {
    position: fixed;
    right: 3%;
    bottom: 3%;
    @include flexbox(column, flex-start, center);
    cursor: pointer;
    transition: all 0.3s linear;

    .customer-img {
      width: max(30px, 3vw);
      height: max(30px, 3vw);
      border-radius: 50%;
      box-shadow: 0 0 6px rgb(0 0 0 / 12%);
      background-color: #fff;
      overflow: hidden;

      >img {
        width: 100%;
        height: 100%;
      }
    }

    .customer-txt {
      color: $primaryColor;
      font-size: 14px;
    }
  }

}

.el-message-box {
  position: fixed;
  font-size: 1vw !important;
  width: max(250px, 20vw) !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
</style>
<style lang="scss" scoped>
.home {
  height: 100%;
  display: flex;
  flex-direction: column;

  .home-content {
    flex: 1;
    overflow: auto;
  }

  .el-backtop {
    color: $primaryColor;
    width: 50px;
    height: 50px;
    font-size: 32px;
  }

  .customer {
    font-size: 14px;
    @include flexbox(column, flex-start, center);
    color: #333;

    .customer-img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .customer-txt {
      color: $primaryColor;
    }
  }
}
</style>
