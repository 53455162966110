import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from "vuex-persistedstate";

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    screenWidth: null,//设备宽度
    theArticle: null, //当前文章
    theArticleList: [], //当前文章列表
    materClassIfy: [], //材料分类
    //材料分类选中
    materIndex: {
      typeIndex: null,
      proClassIndex: null
    },
    goodFenLei:[],//共享页面材料分类
    comInfo: null, //公司主页信息
    // 搜索信息
    search: {
      select: "",
      value: ""
    },
    navIndex: "a",
    // 查看更多文章对象
    caseMore: null,
    infoObj:{
      account:'',
      id:null,
      status:null,
      sex:null,
      phone:'',
      companyid:null,
      name:''
    },//注册后的信息留存
    diZhiList:[]
  },
  mutations: {
    setScreenWidth(state, screenWidth) {
      state.screenWidth = screenWidth;
    },
    setTheArticle(state, theArticle) {
      state.theArticle = theArticle;
    },
    setTheArticleList(state, theArticleList) {
      state.theArticleList = theArticleList;
    },
    setMaterClassIfy(state, materClassIfy) {
      state.materClassIfy = materClassIfy;
    },


    setGoodFenLei(state, goodFenLei) {//共享分类
      state.goodFenLei = goodFenLei;
    },


    setComInfo(state, comInfo) {
      state.comInfo = comInfo;
    },
    setSearch(state, search) {
      state.search = search;
    },
    setInfoObj(state, infoObj){
      state.infoObj = infoObj;
    },
    setDiZhiList(state,diZhiList){
      state.diZhiList = diZhiList
    },
    setMaterIndex(state, materIndex) {
      state.materIndex = materIndex;
    },
    setNavIndex(state, navIndex) {
      state.navIndex = navIndex;
    },
    setCaseMore(state, caseMore) {
      state.caseMore = caseMore;
    },
  },
  actions: {
    setScreenWidth({ commit }, screenWidth) {
      commit("setScreenWidth", screenWidth)
    },
    setTheArticle({ commit }, theArticle) {
      commit("setTheArticle", theArticle)
    },
    setTheArticleList({ commit }, theArticleList) {
      commit("setTheArticleList", theArticleList)
    },
    setMaterClassIfy({ commit }, materClassIfy) {
      commit("setMaterClassIfy", materClassIfy)
    },


    setGoodFenLei({ commit }, goodFenLei) {//共享
      commit("setGoodFenLei", goodFenLei)
    },


    setComInfo({ commit }, comInfo) {
      commit("setComInfo", comInfo)
    },
    setSearch({ commit }, search) {
      commit("setSearch", search)
    },
    setInfoObj({ commit }, infoObj){
      commit("setInfoObj", infoObj)
    },
    setDiZhiList({ commit },diZhiList){
      commit("setDiZhiList", diZhiList)
    },
    setMaterIndex({ commit }, materIndex) {
      commit("setMaterIndex", materIndex)
    },
    setNavIndex({ commit }, navIndex) {
      commit("setNavIndex", navIndex)
    },
    setCaseMore({ commit }, caseMore) {
      commit("setCaseMore", caseMore)
    }
  },
  modules: {
  },
  plugins: [
    persistedState({
      storage: sessionStorage
    })
  ]
})
