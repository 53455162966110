import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '*',
    redirect: {
      name: "Index",
    }
  },
  {
    path: '/Register',//注册
    name: 'Register',
    component: () => import("../views/Register.vue"),
    meta: {
      index: "2",
      keepAlive: false,
      title: "注册",
    }
  },
  {
    path: '/bcce',
    name: 'Home',
    component: Home,
    meta: {
      index: "1",
      keepAlive: false,
      title: "百川商展",
    },
    children: [
      {
        path: 'Index', //首页
        name: 'Index',
        component: () => import("../views/index/Index.vue"),
        meta: {
          index: "1-1",
          keepAlive: true,
          title: "首页",
        }
      },
      {
        path: 'CoreServerSon',//核心服务
        name: 'CoreServerSon',
        component: () => import("../components/index/CoreServerSon.vue"),
        meta: {
          index: "1-67",
          keepAlive: false,
          title: "核心服务"
        }
      },
      {
        path: 'FamousEnt',//首页产业链跳转合作伙伴公司
        name: 'FamousEnt',
        component: () => import("../components/index/FamousEnt.vue"),
        meta: {
          index: "1-77",
          keepAlive: false,
          title: "合作伙伴"
        }
      },
      {
        path: 'XinWaht',//首页新闻与活动跳转更多页
        name: 'XinWaht',
        component: () => import("../components/index/XinWaht.vue"),
        meta: {
          index: "1-78",
          keepAlive: false,
          title: "了解更多"
        }
      },
      {
        path: 'BrandSide', //建店方案
        name: 'BrandSide',
        component: () => import("../views/brandSide/BrandSide.vue"),
        meta: {
          index: "1-2",
          keepAlive: true,
          title: "建店方案",
        }
      }, {
        path: 'DesignCom', //设计优选
        name: 'DesignCom',
        component: () => import("../views/designCom/DesignCom.vue"),
        meta: {
          index: "1-3",
          keepAlive: false,
          title: "设计优选"
        }
      },
      {
        path: 'PropsMake',//道具制作
        name: 'PropsMake',
        component: () => import("../views/propsMake/PropsMake.vue"),
        meta: {
          index: "1-5",
          keepAlive: false,
          title: "道具优选"
        }
      }, {
        path: 'ProvideMater', //材料公司
        name: 'ProvideMater',
        component: () => import("../views/provideMater/ProvideMater.vue"),
        meta: {
          index: "1-6",
          keepAlive: false,
          title: "材料优选"
        }
      },
      {
        path: 'ProvideMaterAll', //全部材料
        name: 'ProvideMaterAll',
        component: () => import("../views/provideMater/ProvideMaterAll.vue"),
        meta: {
          index: "1-6-1",
          keepAlive: false,
          title: "材料优选"
        }
      },
      {
        path: 'PropsMakeAll', //全部材料
        name: 'PropsMakeAll',
        component: () => import("../views/propsMake/PropsMakeAll.vue"),
        meta: {
          index: "1-6-1",
          keepAlive: false,
          title: "道具优选"
        }
      },
      {
        path: 'Decoration', //装修公司
        name: 'Decoration',
        component: () => import("../views/decoration/Decoration.vue"),
        meta: {
          index: "1-7",
          keepAlive: false,
          title: "装潢优选"
        }
      },
      {
        path: 'Supervisor', //监理公司
        name: 'Supervisor',
        component: () => import("../views/supervisor/Supervisor.vue"),
        meta: {
          index: "1-7",
          keepAlive: false,
          title: "监理优选"
        }
      },
      {
        path: 'Logistics',//物流公司
        name: 'Logistics',
        component: () => import("../views/logistics/Logistics.vue"),
        meta: {
          index: "1-8",
          keepAlive: false,
          title: "物流优选"
        }
      },
      {
        path: 'Metaverse',//元宇宙
        name: 'Metaverse',
        component: () => import("../views/metaverse/Metaverse.vue"),
        meta: {
          index: "1-9",
          keepAlive: false,
          title: "元宇宙"
        }
      },
      {
        path: 'MetaverseDetail/:id',//元宇宙详情
        name: 'MetaverseDetail',
        component: () => import("../views/metaverse/MetaverseDetail.vue"),
        meta: {
          index: "1-9-1",
          keepAlive: false,
          title: "元宇宙详情"
        }
      },
      {
        path: 'CaseMore', //全部案例、产品、新闻
        name: 'CaseMore',
        component: () => import("../views/CaseMore.vue"),
        meta: {
          index: "1-0",
          keepAlive: false,
          title: "全部公司"
        }
      },
      {
        path: 'DesignComAll', //全部公司
        name: 'DesignComAll',
        component: () => import("../views/designCom/DesignComAll.vue"),
        meta: {
          index: "1-0",
          keepAlive: false,
          title: "全部公司"
        }
      },
      {
        path: 'ComIndex/:syscompanyid',//公司主页
        name: 'ComIndex',
        component: () => import("../views/ComIndex.vue"),
        meta: {
          index: "1-0",
          keepAlive: false,
          title: "公司主页"
        }
      },
      {
        path: 'FineMaterial',//精致材料
        name: 'FineMaterial',
        component: () => import("../views/FineMaterial.vue"),
        meta: {
          index: "1-0",
          keepAlive: false,
          title: "精致材料"
        }
      },
      {
        path: 'ArticlesDetail/:goodsId',//物品
        name: 'ArticlesDetail',
        component: () => import("../views/ArticlesDetail.vue"),
        meta: {
          index: "1-0",
          keepAlive: false,
          title: "材料详情"
        }
      },
      {
        path: 'ArticlesDetailGood/:goodsId/:isGoodFrom',//物品
        name: 'ArticlesDetailGood',
        component: () => import("../views/ArticlesDetailGood.vue"),
        meta: {
          index: "1-0",
          keepAlive: false,
          title: "商品详情"
        }
      },
      {
        path: 'ExcellentDesign',//优秀设计
        name: 'ExcellentDesign',
        component: () => import("../views/ExcellentDesign.vue"),
        meta: {
          index: "1-0",
          keepAlive: false,
          title: "文章详情"
        }
      },
      {
        path: 'AboutUs',//关于我们
        name: 'AboutUs',
        component: () => import("../views/AboutUs.vue"),
        meta: {
          index: "1-12",
          keepAlive: false,
          title: "关于我们"
        }
      },
      {
        path: 'ProServer',//产品与服务
        name: 'ProServer',
        component: () => import("../views/ProServer.vue"),
        meta: {
          index: "1-0",
          keepAlive: false,
          title: "产品与服务"
        }
      },
      {
        path: "ShareGoods",
        name: "ShareGoods",
        component: () => import("../views/shareGoods/ShareGoods.vue"),
        meta: {
          index: "1-10",
          keepAlive: false,
          title: "闲物互联"
        }
      },
      {
        path: "ShareGoodsAll",
        name: "ShareGoodsAll",
        component: () => import("../views/shareGoods/ShareGoodsAll.vue"),
        meta: {
          index: "1-0",
          keepAlive: false,
          title: "闲物互联-分类页"
        }
      },
      {
        path: "ShareGoodsAll2",
        name: "ShareGoodsAll2",
        component: () => import("../views/shareGoods/ShareGoodsAll2.vue"),
        meta: {
          index: "1-0",
          keepAlive: false,
          title: "闲物互联-分类页2"
        }
      },
      {
        path: "MySelf",
        name: "MySelf",
        component: () => import("../views/myself/MySelf.vue"),
        meta: {
          index: "1-11",
          keepAlive: false,
          title: "个人中心",
          isAuth: true,
        }
      },
      {
        path: "Login",
        name: "Login",
        component: () => import("../views/login and register1/Login.vue"),
        meta: {
          index: "1-12",
          keepAlive: false,
          title: "登录"
        }
      },
      {
        path: "Register1",
        name: "Register1",
        component: () => import("../views/login and register1/Register1.vue"),
        meta: {
          index: "1-13",
          keepAlive: false,
          title: "注册个人"
        }
      },
      {
        path: "Order",
        name: "Order",
        component: () => import("../views/Order.vue"),
        meta: {
          index: "1-14",
          keepAlive: false,
          title: "生成订单",
          isAuth: true,
        }
      },
      {
        path: "GiveMoney",
        name: "GiveMoney",
        component: () => import("../views/GiveMoney.vue"),
        meta: {
          index: "1-15",
          keepAlive: false,
          title: "支付界面",
          isAuth: true,
        }
      },
      {
        path: "lookWhere",
        name: "lookWhere",
        component: () => import("../views/LookWhere.vue"),
        meta: {
          index: "1-18",
          keepAlive: false,
          title: "查看物流",
          isAuth: true,
        }
      },
      {
        path: "InfoPage",
        name: "InfoPage",
        component: () => import("../views/InfoPage.vue"),
        meta: {
          index: "1-16",
          keepAlive: false,
          title: "订单详情",
          isAuth: true,
        }
      },
      {
        path: "MyShops",
        name: "MyShops",
        component: () => import("../views/myShops.vue"),
        meta: {
          index: "1-22",
          keepAlive: false,
          title: "店铺信息"
        }
      },
      {
        path: "GoodOrBad",
        name: "GoodOrBad",
        component: () => import("../views/GoodOrBad.vue"),
        meta: {
          index: "1-32",
          keepAlive: false,
          title: "商品评价",
          isAuth: true,
        }
      },
    ]
  },
  {
    path: '/zzx',
    name: 'zzx',
  }
]


const router = new VueRouter({
  // mode: 'history',
  // base: '/baichuan/',
  routes,
});


router.beforeEach(async (to, from, next) => {
  if (to.name == 'zzx') {
    window.open('https://zzx-1312093770.cos.ap-nanjing.myqcloud.com/uwebgl/bcdp/index1.html', "_blank")
    next('/bcce/Index');
  }

  if (to.meta.isAuth) {//个人中心判断是否登录
    if (window.localStorage.getItem('token') && window.localStorage.getItem('token') != '') {
      next()
    } else {
      next('/bcce/Login');
    }
  }

  let metaIndex = to.meta.index;
  if (metaIndex) {
    if (metaIndex.split("-")[0] === "1") {
      if (metaIndex.split("-")[1] === "0" && from.meta.title) {
        document.title = `百川商展-${from.meta.title}`
      } else {
        document.title = `百川商展-${to.meta.title}`
      }
      next();
    } else if (metaIndex.split("-")[0] === "0") {
      document.title = `百川商展-${to.meta.title}`
      next();
    } else {
      next();
    }
  }
  next();
});

export default router;
