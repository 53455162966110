import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/font_icon/iconfont.css";
import API from "./utils/API";

// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);

import "./plugins/element.js";

Vue.prototype.$api = API;

//注册全局组件
import PageView from "./components/PageView.vue";
Vue.component("page-view", PageView);
import FooterCom from "@/components/FooterCom.vue";
Vue.component("footer-com", FooterCom);

//无限滚动
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll)

import vueAwesomeSwiper from "vue-awesome-swiper/dist/vue-awesome-swiper"
import "swiper/css/swiper.min.css"

Vue.use(vueAwesomeSwiper)

import VueLazyload from "vue-lazyload";
// import loadingImg from "./assets/img/small-loading2.gif";
// import errorImg from "./assets/img/small-error.webp"
Vue.use(VueLazyload, {
  // loading:loadingImg,
  // error:errorImg,
  preLoad: 1.3,
  attempt: 1
});

Vue.config.productionTip = false

//导入动画库
import animated from "animate.css";
Vue.use(animated);

import { WOW } from 'wowjs'
Vue.prototype.$wow = new WOW({
  boxClass: 'wow',
  animateClass: 'animated',
  offset: 100,
  mobile: true,
  live: false,
  callback(box) {
    console.log(box);
  }
})

// vconsole
// const vconsole = require('vconsole')
// Vue.prototype.$vconsole = new vconsole()



new Vue({
  router,
  store,
  render: h => h(App),
  // 预渲染
  // mounted() {
  //   document.dispatchEvent(new Event('render-event'))
  // }
}).$mount('#app')
