<template>
  <div class="home" ref="homeRef">
    <title-nav ref="titleNavRef" :route-name="routeName"></title-nav>
    <div class="home-content">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
  </div>
</template>

<script>

import TitleNav from "@/components/TitleNav.vue";
import { mapActions } from "vuex";
export default {
  components: { TitleNav },
  name: "Home",
  data() {
    return {
      routeName: { newRoute: "", oldRoute: "" },
    };
  },
  watch: {
    // $route(newRoute, oldRoute) {
    //   if (newRoute.name == "Index") {
    //     this.routeName = { newRoute: "", oldRoute: "" };
    //   }
    //   // 公共路由判断选中
    //   const routeArr = ["ComIndex","ExcellentDesign","DesignComAll"];
    //   if (routeArr.indexOf(newRoute.name) != -1) {
    //     this.routeName.oldRoute = oldRoute.meta.index;
    //     this.routeName.newRoute = true;
    //   }
    // },
  },
  created() {
    this.setScreenWidth(document.body.clientWidth);
    window.onresize = () => {
      return (() => {
        this.setScreenWidth(document.body.clientWidth);
      })();
    };
  },
  mounted() {},
  methods: {
    ...mapActions(["setScreenWidth"]),
    lookScrool(v) {
      let titleHeight = this.$refs.titleNavRef.$el.scrollHeight;

      const timeTop = setInterval(() => {
        this.$refs.homeRef.scrollTop += 100;
        if (this.$refs.homeRef.scrollTop >= v - titleHeight) {
          this.$refs.homeRef.scrollTop = v - titleHeight;
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  height: 100%;
  display: flex;
  flex-direction: column;

  .home-content {
    flex: 1;
    overflow: auto;
  }

  .el-backtop {
    color: $primaryColor;
    width: 50px;
    height: 50px;
    font-size: 32px;
  }

  .customer {
    font-size: 14px;
    @include flexbox(column, flex-start, center);
    color: #333;

    .customer-img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .customer-txt {
      color: $primaryColor;
    }
  }
}
</style>
