//使用axios
import axios from "axios";
import Router from '../router/index'
import VueX from '../store/index'

const axiosInstance = axios.create({
  baseURL:"https://server.bcceglobal.com",
  timeout: 8000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

//响应拦截
axiosInstance.interceptors.response.use(
  resp => {
    return resp.data
    // if (resp.status === 200) {
    //   return Promise.resolve(resp.data);
    // } else {
    //   return Promise.reject(resp.data);
    // } 
  },
  error => {
    if (error.response.status == 401) {
      VueX.dispatch('setInfoObj',{
        account:'',
        id:null,
        status:null,
        sex:null,
        phone:'',
        companyid:null,
        name:''
      });
      window.localStorage.removeItem('token')
      Router.push({
        name:'Login'
      })
    }else{
      console.log(error,'响应错误');
    }
  }
);

// 请求拦截
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.token = window.localStorage.getItem('token')
    return config
  },
  error => {
    console.log(error.response, 'response请求错误');
  }
)


export default axiosInstance;
